import request from '@/utils/http'

const loginApi = {
  getcode: phone => `api/app/verificationCode/send/${phone}`,
  checkcode: 'api/wechat/assessment/sms/login/verification',
  userlogin: phone => `api/wechat/assessment/token/${phone}`,
  userinfo: 'api/wechat/assessment/detail',
}
// 获取验证码
export function getVerificatCode(parameter) {
  const { cellphone } = parameter
  return request({
    url: loginApi.getcode(cellphone),
    method: 'get',
  })
}
// 校验验证码
export function checkCode(parameter) {
  return request({
    url: loginApi.checkcode,
    method: 'post',
    data: parameter,
  })
}
// 登录
export function login(parameter) {
  const { cellphone } = parameter
  return request({
    url: loginApi.userlogin(cellphone),
    method: 'post',
  })
}
// 获取用户信息
export function getuserinfo(parameter) {
  return request({
    url: loginApi.userinfo,
    method: 'get',
    params: parameter,
  })
}
