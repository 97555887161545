<template>
  <div class="login">
    <div class="header">
      <h1>全方位评估孩子社交能力</h1>
      <p>80余道社交技能测试题</p>
      <p>北大医疗脑健康BCBA研发</p>
      <div class="header-img"></div>
    </div>
    <div class="content">
      <div class="content-item">
        <img src="@/assets/image/ico_phone.png" />
        <input type="phone" placeholder="请输入您的手机号" v-model="phone" @input="checkPhone($event)" />
      </div>
      <div class="content-item">
        <img src="@/assets/image/ico_phonecode.png" />
        <input type="text" placeholder="请输入验证码" v-model="phoneCode" @input="checkCode($event)" />
        <div class="item-code">
          <div v-show="!isCountdown" @click="getPhoneCode()">获取验证码</div>
          <div v-show="isCountdown">{{ countDown }}&nbsp;s</div>
        </div>
      </div>
      <div class="content-button" @click="gologin()">登录</div>
      <p>北大医疗脑健康研发</p>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getVerificatCode, checkCode, login, getuserinfo } from '@/api/login'
import { getSubject } from '@/api/subject'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
export default {
  name: 'Login',
  data() {
    return {
      isCountdown: false, // 是否倒计时
      countDown: 60,
      timerNum: null, //倒计时定时器编号
      phone: null, // 手机号
      phoneCode: null, // 验证码
    }
  },
  computed: {
    ...mapState(['accessToken']),
    ...mapGetters(['answerID']),
  },
  methods: {
    ...mapMutations(['setToken', 'setId', 'setSubject']),
    ...mapActions(['actanswer']),
    // 手机号位数检查，替换空格
    checkPhone(e) {
      const newPhone = e.target.value.replace(/[\D*]/g, '')
      if (newPhone.length <= 11) {
        this.phone = newPhone
      } else {
        this.phone = newPhone.slice(0, 11)
      }
    },
    // 验证码校验
    checkCode(e) {
      const newCode = e.target.value.replace(/[\D*]/g, '')
      if (newCode.length <= 6) {
        this.phoneCode = newCode
      } else {
        this.phoneCode = newCode.slice(0, 6)
      }
    },
    // 开始倒计时
    startCountdown() {
      this.isCountdown = true
      this.timerNum = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--
        } else {
          this.countDown = 60
          this.isCountdown = false
          clearInterval(this.timerNum)
        }
      }, 1000)
    },
    // 获取验证码
    getPhoneCode() {
      if (this.accessToken) {
        this.setToken({
          token: '',
        })
      }
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!phoneReg.test(this.phone)) {
        Toast('手机号无效')
      } else {
        this.startCountdown()
        // 发送验证码
        getVerificatCode({
          cellphone: this.phone,
        }).then(res => {
          if (res.code !== 0) {
            Toast.fail('验证码发送失败,请重试')
          } else {
            Toast.success('验证码发送成功，注意查收')
          }
        })
      }
    },
    // 验证验证码
    checkphoneCode() {
      if (this.accessToken) {
        this.setToken({
          token: '',
        })
      }
      checkCode({
        cellphone: this.phone,
        verificationCode: this.phoneCode,
      }).then(res => {
        if (res.code !== 0) {
          Toast('验证码错误,请重新输入')
        } else {
          // 手机号登录
          login({
            cellphone: this.phone,
          }).then(res => {
            if (res.code !== 0) {
              // Toast(res.msg)
            } else {
              this.loginSuccess(res)
            }
          })
        }
      })
    },
    // 获取用户信息
    getuser() {
      getuserinfo().then(res => {
        this.setId({
          id: res.data.id,
        })
        // 判断信息，跳转页面，没有用户信息跳转用户页面，未完成答题，确定答题初始编号后跳转，答完题，跳转结果页
        if (!res.data.childName) {
          this.$router.push({
            name: 'personinfo',
          })
        } else if (!res.data.assessmentFinishTime) {
          this.$router.push({
            path: `subject/${this.answerID}`,
          })
        } else {
          this.$router.push({
            name: 'result',
            query: {
              fieldid: 2021030432,
            },
          })
        }
      })
    },
    // 手机号登录成功
    loginSuccess(arg) {
      // 登录成功保存token
      this.setToken({
        token: arg.data.token_type + arg.data.access_token,
      })
      // 获取所有题目,筛选出已回答的题目
      getSubject({
        mainFieldCode: 2021030432,
      }).then(res => {
        if (res.code === 0) {
          this.setSubject(res.data)
          this.actanswer()
          this.getuser()
        }
      })
    },
    // 登录
    gologin() {
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      // 校验验证码
      if (!phoneReg.test(this.phone)) {
        Toast('手机号无效')
      } else if (!this.phoneCode) {
        Toast('验证码不能为空')
      } else {
        this.checkphoneCode()
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.header {
  margin: 34px 0 0;
  text-align: center;

  h1 {
    margin: 0 0 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 26px;
    color: #1d1d47;
  }

  p {
    font-size: 16px;
    margin: 6px 0 0;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #aaaaaa;
  }

  .header-img {
    height: 180px;
    margin: 19px 0 0;
    background: url(~@/assets/image/bg_login_pic.png) center no-repeat;
    background-size: contain;
  }
}
.content {
  width: 296px;
  margin: 32px auto 0;

  .content-item {
    display: flex;
    align-items: center;
    margin: 23px 0 0;
    border-bottom: 1px solid #cccccc;

    img {
      width: 22px;
      height: 22px;
    }

    input {
      width: 180px;
      height: 22px;
      margin: 2px 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #aaaaaa;
    }

    .item-code {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 40px;
      font-size: 14px;
      color: #3350c7;
      text-align: center;
    }
  }

  .content-button {
    width: 295px;
    height: 40px;
    margin: 40px 0 30px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    background: linear-gradient(180deg, #6288e5 0%, #3350c7 100%);
    border-radius: 20px;
  }

  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #aaaaaa;
    text-align: center;
  }
}
</style>
